export default{
	api_path: process.env.VUE_APP_API_HOST + '/api', 
	main_path:process.env.VUE_APP_API_HOST,
	global_user_data: JSON.parse(window.localStorage.getItem('user_data')),
	getPermission: (module)=>{
		
		var permission = [];

		if(!window.localStorage.getItem('user_data')){
			window.location.href = '#/pages/login'
		}
		else{
			var user = window.localStorage.getItem('user_data');
			user = JSON.parse(user);
			permission = user.usertype.permission; 
		} 

		var access = {

			view: false,
			create: false,
			update: false,
			delete: false,
			print: false,
			deliver: false,
			complete: false,
			dispatch: false,
			approve: false,
			cancel: false,
			download: false,
			allowCrud: false

		}
		
		permission = permission.filter((value, index)=>{
			return value.module == module;
		})

		if(permission.length == 0){
			return access
		}
		else{
			permission[0].allowCrud = true;
			if(!permission[0].view && !permission[0].create && !permission[0].update && !permission[0].delete){
				permission[0].allowCrud = false;
			}
			return permission[0];
		}
	},
	getUserDetail: (detail)=>{
		if(!window.localStorage.getItem('user_data')){
			window.location.href = '#/pages/login'
		}
		else{
			var user = window.localStorage.getItem('user_data');
			user = JSON.parse(user); 
			if(detail == 'fullname'){
				return user['firstname']+" "+user['lastname'];
			}
			else{
				return user[detail];
			}
		} 
	},
	has_role : (roles = []) => {
        
        const user_role = JSON.parse(window.localStorage.getItem('user_data'))?.usertype?.setting_name?.toLowerCase();
        const has_roles = roles.filter(val => {
          return val.toLowerCase() == user_role;
        });
        return (has_roles.length > 0);
    },
	menu : {
		_name: 'CSidebarNav',
		_children: [
		{
			_name: 'CSidebarNavItem',
			name: 'Dashboard',
			to: '/home',
			icon: 'cil-speedometer',
		},
		{
			_name: 'CSidebarNavItem',
			name: 'Home',
			to: '/dashboard',
			icon: 'cil-home',
			// badge: {
			//   color: 'primary',
			//   text: 'NEW'
			// }
		},
		{
			_name: 'CSidebarNavItem',
			name: 'Bookings',
			to: '/main/booking',
			icon: 'cil-List'
		},
		{
			_name: 'CSidebarNavDropdown',
			name: 'Billing Management',
			to: '/main',
			icon: 'cibCcMastercard',
			items: [
			{
				name: 'Client Billing',
				to: '/main/client_billing',
				icon: 'cil-Dollar'
			}, 
			{
				name: 'Trucker Settlement',
				to: '/main/trucker_settlement',
				icon: 'cilEnvelopeOpen'
			}, 
	
			]
		},
		
		{
			_name: 'CSidebarNavDropdown',
			name: 'Asset Management',
			to: '/main',
			icon: 'cil-settings',
			items: [
			{
				name: 'Equipment',
				to: '/main/equipment',
				icon: 'cil-GlobeAlt',
				permission: 'equipment'
			}, 
			{
				name: 'Furnishing',
				to: '/main/furnishing',
				icon: 'cil-Layers',
			}, 
			{
				name: 'Vehicles',
				to: '/data/vehicle',
				icon: 'cilSpeedometer',
			}, 
			]
		},
		{
			_name: 'CSidebarNavDropdown',
			name: 'Accounting Management',
			to: '/main',
			icon: 'cil-laptop',
			items: [
			{
				name: 'Payroll',
				to: '/main/payroll',
				icon: 'cilTask',
			}, 
			{
				name: '13th Month',
				to: '/main/13_month',
				icon:"cilMoney",
			}, 
						
			{
				name: 'Personnel Ledger',
				to: '/main/personnel_ledger',
				icon: 'cilCalculator',
			}, 
			{
				name: 'Adjustment',
				to: '/main/adjustment',
				icon: 'cil-GlobeAlt',
			},  
			{
				name: 'Overtime / Late',
				to: '/main/overtime_late',
				icon: 'cilCloudDownload',
			},   
			
			{
				name: 'Working Calendar',
				to: '/main/working_calendar',
				icon: 'cil-calendar',
			},
			{
				name: 'SSS Contribution Table',
				to: '/main/SSS_table',
				icon: 'cilCloudDownload',
			},
			
			]
		},
		{
			_name: 'CSidebarNavDropdown',
			name: 'Reminders',
			to: '/main',
			icon: 'cilBell',
			items: [
			{ 
				// temporary comment out for enhancement
				// name: 'Renewal Reminders',
				name: 'Asset Renewals',
				to: '/data/renewal_reminder',
				icon: 'cil-list',
			},
	
			// { temporary comment out for enhancement
			//   name: 'Service Reminders',
			//   to: '/data/service_reminder',
			//   icon: 'cil-settings',
			// }, 
			{
				// name: 'License Renewals', rename to Personnel Renewals
				name: 'Personnel Renewals',
				to: '/data/license_renewal_reminder',
				icon: 'cil-user',
			}, 
			]
		},
		{
			_name: 'CSidebarNavItem', 
			name: 'Incidents',
			to: '/main/incident',
			icon: 'cil-LocationPin',
		},
		{
			_name: 'CSidebarNavDropdown',
			name: 'Inventory Management',
			to: '/main',
			icon: 'cil-settings',
			items: [
			{
				
				name: 'CSidebarNavItem',
				name: 'Parts Inventory',
				to: '/main/parts_inventory',
				icon: 'cil-speech',
			},
				
			
			]
		},
		// {
		//   _name: 'CSidebarNavItem',
		//   name: 'Parts Inventory',
		//   to: '/main/parts_inventory',
		//   icon: 'cil-speech',
		// },
		{
			_name: 'CSidebarNavItem',
			name: 'Work Order',
			to: '/main/work_order',
			icon: 'cil-task',
		},
		{
			_name: 'CSidebarNavItem',
			name: 'Fuel Records',
			to: '/data/fuel_record',
			icon: 'cil-speedometer',
		},
		{
			_name: 'CSidebarNavItem', 
			name: 'Reports',
			to: '/main/reports', 
			icon: 'cil-ChartPie'
		},
		// {
		//   _name: 'CSidebarNavItem',
		//   name: 'Charts',
		//   to: '/charts',
		//   icon: 'cil-chart-pie'
		// },
		]
	},
	menu_permission : [
		{
			name: 'Dashboard',
			to: '/home',
			permission: null
		},
		{
			name: 'Home',
			to: '/dashboard',
			permission: null
		},
		{
			name: 'Bookings',
			to: '/main/booking',
			permission: 'booking'
		},
		{
			name: 'Client Billing',
			to: '/main/client_billing',
			permission: 'client billing'
		}, 
		{
			name: 'Trucker Settlement',
			to: '/main/trucker_settlement',
			permission: 'trucker_settlement'
		}, 	
		{
			name: 'Equipment',
			to: '/main/equipment',
			permission: 'equipment'
		}, 
		{
			name: 'Furnishing',
			to: '/main/furnishing',
			permission: 'furnishing'
		}, 
		{
			name: 'Vehicles',
			to: '/data/vehicle',
			permission: 'equipment'
		}, 
		{
			name: 'Payroll',
			to: '/main/payroll',
			permission: 'payroll'
		}, 
		{
			name: '13th Month',
			to: '/main/13_month',
			permission: '13th_month'
		}, 
					
		{
			name: 'Personnel Ledger',
			to: '/main/personnel_ledger',
			permission: 'personnel_ledger'
		}, 
		{
			name: 'Adjustment',
			to: '/main/adjustment',
			permission: 'adjustment',
		},  
		{
			name: 'Overtime / Late',
			to: '/main/overtime_late',
			permission: 'overtime_late'
		},   
		
		{
			name: 'Working Calendar',
			to: '/main/working_calendar',
			permission: 'working_calendar'
		},
		{
			name: 'SSS Contribution Table',
			to: '/main/SSS_table',
			permission: 'sss',
		},
		{
			// temporary comment out for enhancement
			// name: 'Renewal Reminders',
			name: 'Asset Renewals',
			to: '/data/renewal_reminder',
			permission: 'renewal_reminder'
		},

		// { temporary comment out for enhancement
		//   name: 'Service Reminders',
		//   to: '/data/service_reminder',
		//   icon: 'cil-settings',
		// }, 
		{
			name: 'Personnel Renewals',
			to: '/data/license_renewal_reminder',
			permission: 'license_reminder'
		}, 
		{
			_name: 'CSidebarNavItem', 
			name: 'Incidents',
			to: '/main/incident',
			permission: 'incident'
		},
		{
			name: 'Parts Inventory',
			to: '/main/parts_inventory',
			permission: 'parts_inventory'
		},	
		{
			name: 'Work Order',
			to: '/main/work_order',
			permission: 'work_order'
		},
		{
			name: 'Fuel Records',
			to: '/data/fuel_record',
			permission: 'fuel_record'
		},
		{
			name: 'Reports',
			to: '/main/reports', 
			permission: 'reports'
		},
	],
}